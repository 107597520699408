import React from "react";

const LocationIcon = ({
  width = "24",
  height = "24",
  fill = "none",
  className = "",
}) => (
  <span className={className}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_507_5765)">
        <path
          d="M6 9.50519C6 6.46519 8.46519 4 11.5052 4C14.5452 4 17.0104 6.46519 17.0104 9.50519C17.0104 12.5452 11.5052 20 11.5052 20C11.5052 20 6 12.5452 6 9.50519Z"
          fill="#E5242F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5052 6.9837C10.1315 6.9837 9.01783 8.09735 9.01783 9.4711C9.01783 10.8449 10.1315 11.9585 11.5052 11.9585C12.879 11.9585 13.9926 10.8449 13.9926 9.4711C13.9926 8.09735 12.879 6.9837 11.5052 6.9837ZM8.64746 9.4711C8.64746 7.8928 9.92693 6.61333 11.5052 6.61333C13.0835 6.61333 14.363 7.8928 14.363 9.4711C14.363 11.0494 13.0835 12.3289 11.5052 12.3289C9.92693 12.3289 8.64746 11.0494 8.64746 9.4711Z"
          fill="#010101"
        />
      </g>
      <defs>
        <clipPath id="clip0_507_5765">
          <rect
            width="11.0104"
            height="16"
            fill="white"
            transform="translate(6 4)"
          />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export default LocationIcon;
